<ion-button
  (click)="handleClick()"
  [color]="buttonSettings.color"
  [expand]="buttonSettings.expand"
  [fill]="buttonSettings.fill"
  [class]="buttonSettings.class"
  [size]="buttonSettings.size"
  mode="ios"
  [disabled]="isDisabled"
  [type]="formSubmit ? 'submit' : 'button'"
>
  @if (iconSrc) {
    <ion-icon [src]="iconSrc" class="mr-5"></ion-icon>
  }
  <span [class]="buttonSettings.textStyle" class="align-center">
    <ion-spinner [duration]="LOADING_SPINNER_DURATION_MS" [name]="spinner" class="mr-10" *ngIf="loading" />
    {{ label }}
    <ng-content />
  </span>
</ion-button>
