import { defaultTheme } from './default-theme';
import { ThemeInterface } from './theme-interface';
import { getThemeIconsLight, ThemeMap } from './theme-map';

const redEarthTheme: ThemeInterface = {
  name: ThemeMap.RED_EARTH,
  type: defaultTheme.type,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#000000' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#000000' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#1a1a1a' },
    ],
    textPrimary: defaultTheme.styles.textPrimary,
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: defaultTheme.styles.textSecondary,
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#F6F6F6' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '246, 246, 246' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#d8d8d8' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#f7f7f7' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#EC543F' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '236, 84, 63' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255,255,255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#d04a37' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#ee6552' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#BE3A32' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '190, 58, 50' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#a7332c' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#c54e47' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#3A245E' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '58,36,94' },
      // at the moment using the contrast value for the tab labels
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#BE3A32' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '190, 58, 50' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#332053' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#4e3a6e' },
    ],
    solar: defaultTheme.styles.solar,
    consumption: defaultTheme.styles.consumption,
    battery: defaultTheme.styles.battery,
    consumed: defaultTheme.styles.consumed,
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.RED_EARTH],
    style: {
      height: '30px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    primary: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' },
    light: { value: '#000000', opacValue: 'rgba(0, 0, 0, 0.4)' },
    secondary: { value: '#BE3A33', opacValue: 'rgba(190, 58, 51, 0.4)' },
    tertiary: { value: '#008A32', opacValue: 'rgba(0, 178, 153, 0.4)' },

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#4696EC', opacValue: 'rgba(70, 150, 236, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#EF4239',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#000000' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#000000' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#1a1a1a' },
    ],
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#EF4239' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '239,66,57' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#d23a32' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#f1554d' },
    ],
    success: [
      { themeVariable: '--ion-color-success', value: '#44865E' },
      { themeVariable: '--ion-color-success-rgb', value: '68, 134, 94' },
      { themeVariable: '--ion-color-success-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-success-shade', value: '#3c7653' },
      { themeVariable: '--ion-color-success-tint', value: '#57926e' },
    ],
    warning: [
      { themeVariable: '--ion-color-warning', value: '#F4B44E' },
      { themeVariable: '--ion-color-warning-rgb', value: '244, 180, 78' },
      { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-shade', value: '#d79e45' },
      { themeVariable: '--ion-color-warning-tint', value: '#f5bc60' },
    ],
    danger: [
      { themeVariable: '--ion-color-danger', value: '#BE3A32' },
      { themeVariable: '--ion-color-danger-rgb', value: '190, 58, 50' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#a7332c' },
      { themeVariable: '--ion-color-danger-tint', value: '#c54e47' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#B5EECC' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '181, 238, 204' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#9fd1b4' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#bcf0d1' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FEEACB' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '254, 234, 203' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#e0ceb3' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#feecd0' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FFDFDF' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '255, 223, 223' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#e0c4c4' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#ffe2e2' },
    ],
  },
  svgIcons: {
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#EC543F' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '236, 84, 63' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#d04a37' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#ee6552' },
    ],
  },
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#F8F8F8' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '248, 248, 248' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dadada' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#f9f9f9' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#ECECEC' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '236, 236, 236' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d0d0d0' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#eeeeee' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#F8F8F8' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '248, 248, 248' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dadada' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#f9f9f9' },
    ],
  },
};

export { redEarthTheme };
