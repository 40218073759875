import { Injectable, inject } from '@angular/core';
import { BaseApiService } from './base.api-service';
import { EndpointsApi } from 'app/api/units/endpoints.api';

export interface GetEndpointRequestParams {
  endpointUuid?: string;
}

export const queryKeys = {
  all: ['endpoints'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: (params: GetEndpointRequestParams) => [...queryKeys.details(), params.endpointUuid] as const,
  upgradeDroplet: (params: GetEndpointRequestParams) =>
    [...queryKeys.details(), params.endpointUuid, 'upgradeDroplet'] as const,
};

@Injectable({
  providedIn: 'root',
})
export class EndpointsApiService extends BaseApiService {
  readonly #endpointsApi = inject(EndpointsApi);

  constructor() {
    super('/api/v1/endpoints');
  }

  getEndpoint(params: GetEndpointRequestParams) {
    return this._query({
      queryKey: queryKeys.detail(params),
      queryFn: async () => {
        const res = await this.#endpointsApi.getEndpointData(params.endpointUuid);

        return res.data;
      },
      enabled: !!params.endpointUuid,
      staleTime: 30000,
    });
  }

  upgradeDroplet(params: GetEndpointRequestParams) {
    return this._mutation({
      mutationFn: async () => {
        const res = await this.#endpointsApi.upgradeEndpoint(params.endpointUuid);
        return res.data;
      },
    });
  }
}
