import { BooleanStrings, DataTypeStrings, DropletHardwareVersion } from '@class/commons/enums';
import { BrowserLogger } from '@class/core/browser-logger';
import { TranslationsService } from '@service/common/translations.service';
import { DropletAttribute } from '../droplet/droplet-attribute.model';
import { DropletControllerControlKey } from '../droplet/droplet-controller-control-key.model';
import { DropletControllerParameter } from '../droplet/droplet-controller-parameter.model';
import { DropletController } from '../droplet/droplet-controller.model';
import { DropletDeviceConnectionAttribute } from '../droplet/droplet-device-connection-attribute.model';
import { DropletDeviceControl } from '../droplet/droplet-device-control.model';
import { DropletDevice } from '../droplet/droplet-device.model';
import { DropletDisplay, DropletOverview } from '../droplet/droplet-display.model';
import { DropletMetric } from '../droplet/droplet-metric.model';
import { NumericParameterConfig, ToggleParameterConfig } from './endpoint-config-json.model';

import {
  EndpointController,
  EndpointControllerControlKey,
  EndpointControllerParameter,
  EndpointMetric,
} from '../../../services/units/units.types';

export class EndpointMetricDTO implements EndpointMetric {
  dashboard_config: string;
  display_decimals: number;
  display_order: number;
  id: string;
  is_iec61850: boolean;
  key: string;
  name: string;
  units_abbr: string;
  units_desc: string;
  // additional controller sync payload properties
  uuid?: string;
  controller_type?: string;

  public static adaptToDropletMetric(metricDTO: EndpointMetricDTO, itemNumber: number): DropletMetric {
    const metricKey = createMetricKey(metricDTO.key, itemNumber);
    const metricKeyWithoutDeviceNumber = createMetricKeyWithoutDeviceNumber(metricDTO.key);
    const streamName = createStreamName(metricDTO.name);
    const result: DropletMetric = {
      values: [],
      times: [],
      valueUpdated: false,
      viewInStreamTab: false,
      dashboardConfig: metricDTO.dashboard_config,
      displayDecimals: metricDTO.display_decimals,
      displayOrder: metricDTO.display_order,
      metricKey: metricKey,
      metricKeyWithoutDeviceNumber: metricKeyWithoutDeviceNumber,
      streamName: streamName,
      unitsAbbr: metricDTO.units_abbr,
      unitsDesc: metricDTO.units_desc,
      isIec61850: metricDTO.is_iec61850,
      name: metricDTO.name,
    };
    BrowserLogger.log('EndpointMetricDTO.adaptToDropletMetric', {
      result,
      metricDTO,
      itemNumber,
    });
    return result;
  }

  public static adaptToDropletMetrics(
    metricsPayload: EndpointMetricDTO[],
    controllerPayload: EndpointControllerDTO,
  ): DropletMetric[] {
    const result = metricsPayload?.length
      ? metricsPayload.map((metric: EndpointMetricDTO) =>
          EndpointMetricDTO.adaptToDropletMetric(metric, controllerPayload.number),
        )
      : [];
    BrowserLogger.log('EndpointMetricDTO.adaptToDropletMetrics', { result, controllerPayload });
    return result;
  }
}

const createStreamName = (metricName: string): string => {
  // todo - need to get the backend to send this in the correct format so we do not need to work out client side.
  //        let’s say there is a name that is like "device - device - metric - something" it’ll only take out the
  //        first one from the string
  return metricName.substring(metricName.indexOf(' - ') + 3);
};

const createMetricKey = (metricKey: string, itemNumber: number): string => {
  if (itemNumber && itemNumber > 0) {
    return metricKey.includes('{0}') ? metricKey.replace('{0}', itemNumber.toString()) : metricKey;
  }
  return metricKey;
};

const createMetricKeyWithoutDeviceNumber = (metricKey: string): string => {
  // this is pain! we need to check with droplet guys..
  // probably need to push this so they can fix stuff on their end so we don’t have to create this.
  // what’s happening is, there are some old devices that are publishing values without device number in dev handler
  return metricKey.includes('{0}') ? metricKey.replace('{0}', '') : metricKey;
};

export type DeviceInfo = {
  [key: string]: { [key: string]: number | string };
};

export type EndpointControllerTypeDTO = {
  uuid: string;
  id: string;
  identifier: string;
  name: string;
  description: string;
  control_handle: string;
  controller_type_parameters: EndpointControllerParameterDTO[];
  device_types: string[];
  valid_iec61850_device_type_ids: string[];
  valid_legacy_device_type_ids: string[];
  controller_device_limit: number;
  toggle: boolean;
};

export class EndpointControllerControlKeyDTO implements EndpointControllerControlKey {
  amount_key: string;
  api_available: boolean;
  api_key: string;
  control_type: string;
  controller_type_id: string;
  description: string;
  display_config: string;
  display_order: boolean;
  id: string;
  name: string;
  set_key: string;
  status_key: string;
  // additional controller sync payload properties
  uuid?: string;
  controller_type?: string;
  control_function?: number;

  private static adaptToDropletControllerControlKey(
    controllerControlKeyDTO: EndpointControllerControlKeyDTO,
  ): DropletControllerControlKey {
    const result = new DropletControllerControlKey();
    result.amountKey = controllerControlKeyDTO.amount_key;
    result.apiAvailable = controllerControlKeyDTO.api_available;
    result.apiKey = controllerControlKeyDTO.api_key;
    result.controlType = controllerControlKeyDTO.control_type;
    result.controllerTypeId = controllerControlKeyDTO.controller_type_id;
    result.description = controllerControlKeyDTO.description;
    result.displayConfig = controllerControlKeyDTO.display_config;
    result.id = controllerControlKeyDTO.id;
    result.name = controllerControlKeyDTO.name;
    result.setKey = controllerControlKeyDTO.set_key;
    result.statusKey = controllerControlKeyDTO.status_key;
    BrowserLogger.log('EndpointControllerControlKeyDTO.adaptToDropletControllerControlKey', {
      result,
      controllerControlKeyDTO,
    });
    return result;
  }

  public static adaptToDropletControllerControlKeys(
    controlKeysPayload: EndpointControllerControlKeyDTO[],
  ): DropletControllerControlKey[] {
    const result = controlKeysPayload?.length
      ? controlKeysPayload.map((controlKey: EndpointControllerControlKeyDTO) =>
          EndpointControllerControlKeyDTO.adaptToDropletControllerControlKey(controlKey),
        )
      : [];
    BrowserLogger.log('EndpointControllerControlKeyDTO.adaptToDropletControllerControlKeys', {
      result,
      controlKeysPayload,
    });
    return result;
  }
}

export class EndpointControllerParameterDTO implements EndpointControllerParameter {
  controller_id: string;
  default_value: string;
  description: string;
  display_config: string;
  display_config_json: { numeric?: NumericParameterConfig; toggle?: ToggleParameterConfig };
  display_order: number;
  id: string;
  name: string;
  value?: string | boolean;
  parameter_type?: string;
  uuid?: string;
  controller_type?: string;
  user_configurable?: boolean;
  valid?: boolean;

  private static adaptToDropletControllerParameter(
    controllerParameterDTO: EndpointControllerParameterDTO,
    translationsService: TranslationsService,
    existingParameter?: DropletControllerParameter,
  ): DropletControllerParameter {
    const result = new DropletControllerParameter();
    result.controllerId = controllerParameterDTO.controller_id;
    result.defaultValue = controllerParameterDTO.default_value;
    result.description = controllerParameterDTO.description;
    result.displayConfig = controllerParameterDTO.display_config;
    result.displayConfigJson =
      controllerParameterDTO.display_config_json || JSON.parse(controllerParameterDTO.display_config); // in-case it has not been parsed yet
    result.id = controllerParameterDTO.id;
    result.name = controllerParameterDTO.name;
    result.parameterType = controllerParameterDTO.parameter_type;
    result.value = existingParameter?.value !== undefined ? existingParameter.value : controllerParameterDTO.value; // use existing value if we have one
    result.type = DropletDeviceControl.getControlTypeFromDisplayConfig(result.displayConfigJson);
    result.placeholder = DropletDeviceControl.getControlPlaceholderFromDisplayConfig(
      result.displayConfigJson,
      result.type,
      translationsService,
    );
    fixIncomingBooleanParameter(result);
    BrowserLogger.log('EndpointControllerParameterDTO.adaptToDropletControllerParameter', {
      result,
      controllerParameterDTO,
      existingParameter,
    });
    return result;
  }

  public static adaptToDropletControllerParameters(
    payload: EndpointControllerParameterDTO[],
    translationsService: TranslationsService,
    existingParameters?: DropletControllerParameter[],
  ): DropletControllerParameter[] {
    const result = payload?.length
      ? payload.map((controllerParam: EndpointControllerParameterDTO) =>
          EndpointControllerParameterDTO.adaptToDropletControllerParameter(
            controllerParam,
            translationsService,
            existingParameters?.find((p) => p.name === controllerParam.name), // match parameter on name as id's wont match between existing parameters and new/empty parameters
          ),
        )
      : [];
    BrowserLogger.log('EndpointControllerParameterDTO.adaptToDropletControllerParameters', {
      result,
      payload,
      existingParameters,
    });
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getDefaultValue(param: EndpointControllerParameterDTO): any {
    let result = undefined;
    // if not optional, get the default
    if (!EndpointControllerParameterDTO.isOptional(param)) {
      // cast booleans to its correct type
      if (param?.parameter_type === DataTypeStrings.BOOLEAN) {
        result = param.default_value === BooleanStrings.FALSE ? false : true;
      } else {
        result = param.default_value;
      }
    }
    BrowserLogger.log('EndpointControllerParameterDTO.getDefaultValue', { result, param });
    return result;
  }

  public static isNullable(param: EndpointControllerParameterDTO): boolean {
    // is the appropriate 'nullable' display config set
    const result = param?.display_config_json?.toggle?.nullable || param?.display_config_json?.numeric?.nullable;
    BrowserLogger.log('EndpointControllerParameterDTO.isNullable', { result, param });
    return result;
  }

  public static isOptional(param: EndpointControllerParameterDTO): boolean {
    // is the appropriate 'optional' display config set
    const result = param?.display_config_json?.toggle?.optional || param?.display_config_json?.numeric?.optional;
    BrowserLogger.log('EndpointControllerParameterDTO.isOptional', { result, param });
    return result;
  }
}

export type EndpointControllerParameterAddUpdateDTO = {
  parameter_type_id: string;
  value: string;
};

export type EndpointControllerAddDTO = {
  controller_info: {
    name: string;
    controller_type_id: string;
    device_ids: string[];
    parameters: EndpointControllerParameterAddUpdateDTO[];
  };
  endpoint_info: {
    endpoint_id: string;
    uuid: string;
  };
};

export type EndpointControllerUpdateDTO = {
  id: string;
  name: string;
  device_ids: string[];
  parameters: EndpointControllerParameterAddUpdateDTO[];
  uuid: string;
};

export class EndpointControllerDTO implements EndpointController {
  control_keys: EndpointControllerControlKeyDTO[];
  control_metrics: EndpointMetricDTO[];
  controller_type: string;
  controller_type_id: string;
  controller_unique_id: string;
  controller_version_number: number;
  description: string;
  device_ids: string[];
  id: string;
  name: string;
  number: number;
  parameters: EndpointControllerParameterDTO[];
  target_endpoint_id: string;

  public static adaptToDropletController(
    controllerDTO: EndpointControllerDTO,
    translationsService: TranslationsService,
  ): DropletController {
    const result = new DropletController();
    if (controllerDTO && translationsService) {
      result.firstSeen = new Date(); // todo - use Luxon for date
      result.lastSeen = new Date(); // todo - use Luxon for date
      result.controlKeys = EndpointControllerControlKeyDTO.adaptToDropletControllerControlKeys(
        controllerDTO.control_keys,
      );
      result.controlMetrics = EndpointMetricDTO.adaptToDropletMetrics(controllerDTO.control_metrics, controllerDTO);
      result.controllerType = controllerDTO.controller_type;
      result.controllerTypeId = controllerDTO.controller_type_id;
      result.controllerUniqueId = controllerDTO.controller_unique_id;
      result.controllerVersionNumber = controllerDTO.controller_version_number;
      result.description = controllerDTO.description;
      result.deviceIds = controllerDTO.device_ids;
      result.id = controllerDTO.id;
      result.metrics = [];
      result.metricsForStreamTab = [];
      result.name = controllerDTO.name;
      result.number = controllerDTO.number;
      result.parameters = EndpointControllerParameterDTO.adaptToDropletControllerParameters(
        controllerDTO.parameters,
        translationsService,
      );
      result.statusValue = false;
      result.targetEndpointId = controllerDTO.target_endpoint_id;
      result.viewInStreamTab = false;
      result.controllerExistInDatabase = true; // came from database API so must exist in dbase
    }
    BrowserLogger.log('EndpointControllerDTO.adaptToDropletController ', { result, controllerDTO });
    return result;
  }

  public static adaptToDropletControllers(
    controllersPayload: EndpointControllerDTO[],
    translationsService: TranslationsService,
  ): DropletController[] {
    const result = controllersPayload?.length
      ? controllersPayload.map((controllerDTO: EndpointControllerDTO) =>
          EndpointControllerDTO.adaptToDropletController(controllerDTO, translationsService),
        )
      : [];
    BrowserLogger.log('EndpointControllerDTO.adaptToDropletControllers', { result });
    return result;
  }
}

export class EndpointDeviceConnectionAttributeDTO {
  connection_attribute_name: string;
  connection_type: string;
  id: string;
  value: string;

  public static adaptToDropletDeviceConnectionAttribute(
    deviceConnectionAttributeDTO: EndpointDeviceConnectionAttributeDTO,
  ): DropletDeviceConnectionAttribute {
    const result: DropletDeviceConnectionAttribute = {
      connectionAttributeName: deviceConnectionAttributeDTO.connection_attribute_name,
      connectionType: deviceConnectionAttributeDTO.connection_type,
      id: deviceConnectionAttributeDTO.id,
      value: deviceConnectionAttributeDTO.value,
    };
    BrowserLogger.log('EndpointDeviceConnectionAttributeDTO.adaptToDropletDeviceConnectionAttribute', {
      result,
      deviceConnectionAttributeDTO,
    });
    return result;
  }

  public static adaptToDropletDeviceConnectionAttributes(
    deviceConnectionAttributeDTO: EndpointDeviceConnectionAttributeDTO[],
  ): DropletDeviceConnectionAttribute[] {
    const result = deviceConnectionAttributeDTO?.length
      ? deviceConnectionAttributeDTO.map((attribute: EndpointDeviceConnectionAttributeDTO) =>
          EndpointDeviceConnectionAttributeDTO.adaptToDropletDeviceConnectionAttribute(attribute),
        )
      : [];
    BrowserLogger.log('EndpointDeviceConnectionAttributeDTO.adaptToDropletDeviceConnectionAttributes', {
      result,
      deviceConnectionAttributeDTO,
    });
    return result;
  }
}

export class EndpointDeviceControlDTO {
  current_value: number | string;
  description: string;
  display_config: string;
  display_order: number;
  id: string;
  is_iec61850: boolean;
  name: string;
  set_key: string;
  status_key: string;

  public static adaptToDropletDeviceControls(
    endpointDeviceControlsDTO: EndpointDeviceControlDTO[],
    devicePayloadNumber: number,
    translationsService: TranslationsService,
  ): DropletDeviceControl[] {
    const result = endpointDeviceControlsDTO?.length
      ? endpointDeviceControlsDTO.map((control: EndpointDeviceControlDTO) =>
          adaptEndpointDeviceControlDTOToDropletDeviceControl(control, devicePayloadNumber, translationsService),
        )
      : [];
    BrowserLogger.log('EndpointDeviceControlDTO.adaptToDropletDeviceControls', {
      result,
      endpointDeviceControlsDTO,
    });
    return result;
  }
}

const adaptEndpointDeviceControlDTOToDropletDeviceControl = (
  endpointDeviceControlDTO: EndpointDeviceControlDTO,
  deviceNumber: number,
  translationsService: TranslationsService,
): DropletDeviceControl => {
  const result = new DropletDeviceControl();
  const displayConfigJson = JSON.parse(endpointDeviceControlDTO.display_config);
  const controlType = DropletDeviceControl.getControlTypeFromDisplayConfig(displayConfigJson);
  const placeholder = DropletDeviceControl.getControlPlaceholderFromDisplayConfig(
    displayConfigJson,
    controlType,
    translationsService,
  );

  result.description = endpointDeviceControlDTO.description;
  result.displayConfig = endpointDeviceControlDTO.display_config;
  result.displayConfigJson = displayConfigJson;
  result.setKey = generateKeyWithDeviceNumber(endpointDeviceControlDTO.set_key, String(deviceNumber));
  result.statusKey = endpointDeviceControlDTO.status_key;
  result.statusKeyWithDeviceNumber = generateKeyWithDeviceNumber(
    endpointDeviceControlDTO.status_key,
    String(deviceNumber),
  );
  result.id = endpointDeviceControlDTO.id;
  result.isIec61850 = endpointDeviceControlDTO.is_iec61850;
  result.name = endpointDeviceControlDTO.name;
  result.type = controlType;
  result.isSetting = endpointDeviceControlDTO.current_value != null;
  result.backendValue = endpointDeviceControlDTO.current_value ? endpointDeviceControlDTO.current_value : undefined;
  result.placeholder = placeholder;
  BrowserLogger.log('adaptEndpointDeviceControlDTOToDropletDeviceControl', {
    result,
    endpointDeviceControlDTO,
  });
  return result;
};

const generateKeyWithDeviceNumber = (key: string, deviceNumber: string): string => {
  return key.indexOf('{0}') === -1 ? key : key.replace('{0}', deviceNumber);
};

export class EndpointDeviceDTO {
  uuid: string;
  connection_attributes: EndpointDeviceConnectionAttributeDTO[];
  controls: EndpointDeviceControlDTO[];
  device_type_category: string;
  device_type_id: string;
  device_type_name: string;
  full_name: string;
  id: string;
  dev_handle: string;
  metrics: EndpointMetricDTO[];
  name: string;
  number: number;
  resource_type: string;
  settings: EndpointDeviceControlDTO[];
  short_name: string;
  //iec16850 devices properties
  droplet_device_unique_id?: string;
  generic_device_type_name?: string;
  iec61850_device_type_id?: string;
  logical_device_type_id?: string;
  manufacturer_name?: string;
  model_name?: string;
  model_number?: number;
  protocol?: string;
  slave_id?: number;
  serial_no: string;
  automated_test_supported: boolean;
  device_attributes: {
    name: string;
    value: number | string | boolean;
  }[];

  public static adaptToDropletDevice(
    devicePayload: EndpointDeviceDTO,
    endpointPayload: EndpointDTO | undefined,
    translationsService: TranslationsService,
  ): DropletDevice {
    BrowserLogger.log('EndpointDeviceDTO.adaptToDropletDevice', { devicePayload, endpointPayload });
    try {
      const result = new DropletDevice();
      let controllers: DropletController[];
      let deviceInfo: DeviceInfo;
      if (endpointPayload) {
        controllers = EndpointControllerDTO.adaptToDropletControllers(
          endpointPayload.controllers,
          translationsService,
        ).filter((adaptedController) => adaptedController.deviceIds.includes(devicePayload.id));

        if (endpointPayload.endpoint_attributes && endpointPayload.endpoint_attributes.devices) {
          // TODO: remove this when droplet team gives confirmation that
          // the droplet will send the device detail under device dev_handle
          const deviceDetailOnDeviceType = endpointPayload.endpoint_attributes.devices[devicePayload.device_type_name];
          const deviceDetailOnDevHandle = endpointPayload.endpoint_attributes.devices[devicePayload.dev_handle];
          deviceInfo = deviceDetailOnDeviceType ? deviceDetailOnDeviceType : deviceDetailOnDevHandle;
        }
      }

      let adaptedMetrics: DropletMetric[];
      let statusKeys: string[];
      if (devicePayload.metrics) {
        adaptedMetrics = EndpointDeviceDTO.adaptToDropletMetrics(devicePayload, endpointPayload);
        statusKeys = adaptedMetrics.map((metric: DropletMetric) => metric.metricKey);
      }

      // todo - need to change these dates according to unit timezone
      result.firstSeen = new Date();
      result.lastSeen = new Date();

      result.connectionAttributes = EndpointDeviceConnectionAttributeDTO.adaptToDropletDeviceConnectionAttributes(
        devicePayload.connection_attributes,
      );
      result.controls = EndpointDeviceControlDTO.adaptToDropletDeviceControls(
        devicePayload.controls,
        devicePayload.number,
        translationsService,
      );
      result.deviceTypeName = devicePayload.device_type_name;
      result.fullName = devicePayload.full_name;
      result.id = devicePayload.id;
      result.uuid = devicePayload.uuid;
      result.metrics = adaptedMetrics;
      result.metricsForStreamTab = [];
      result.name = devicePayload.name;
      result.number = devicePayload.number;
      result.settings = EndpointDeviceControlDTO.adaptToDropletDeviceControls(
        devicePayload.settings,
        devicePayload.number,
        translationsService,
      );
      result.shortName = devicePayload.short_name;
      result.viewInStreamTab = false;
      result.serialNo = devicePayload.serial_no;
      result.genericDeviceTypeName = devicePayload.generic_device_type_name;
      result.deviceTypeId = devicePayload.device_type_id;
      result.deviceTypeCategory = devicePayload.device_type_category;
      result.deviceInfo = deviceInfo;
      result.dropletDeviceUniqueId = devicePayload.droplet_device_unique_id;
      result.logicalDeviceTypeId = devicePayload.logical_device_type_id;
      result.iec61850DeviceTypeId = devicePayload.iec61850_device_type_id;
      result.manufacturerName = devicePayload.manufacturer_name;
      result.modelName = devicePayload.model_name;
      result.modelNumber = devicePayload.model_number;
      result.protocol = devicePayload.protocol;
      result.slaveId = devicePayload.slave_id;
      result.controllers = controllers;
      result.statusKeys = statusKeys;
      result.deviceAttributes = devicePayload.device_attributes;
      BrowserLogger.log('EndpointDeviceDTO.adaptToDropletDevice', { result, devicePayload, endpointPayload });
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }

  public static adaptToDropletMetrics(endpointDeviceDTO: EndpointDeviceDTO, endpointDTO: EndpointDTO): DropletMetric[] {
    const result = endpointDeviceDTO.metrics.map((metric: EndpointMetricDTO) =>
      EndpointMetricDTO.adaptToDropletMetric(metric, endpointDeviceDTO.number),
    );
    BrowserLogger.log('EndpointDeviceDTO.adaptToDropletMetrics', { result, endpointDeviceDTO, endpointDTO });
    return result;
  }
}

export enum DeviceHealth {
  Ok = 'ok',
  Warn = 'warn',
  Fault = 'fault',
}

/**
 * Sample Endpoint Attributes
 *
 * {
    "ip_netmask": "255.255.255.0",
    "hw_version": "droplet_one 1.0",
    "python_version": "3.8.11",
    "gateway_interface": "eth0",
    "python_implementation": "CPython",
    "hostname": "Dropletb827ebae1ae8",
    "devices": {
        "EG1": {
            "Battery": {
                "Active kW Rating - Charge": 6.52,
                "Active kW Rating - Discharge": 6.52,
                "Ah Capacity": 252,
                "Battery Type": 5,
                "Max Reserve SOC": 98,
                "Min Reserve SOC": 6,
                "Number of Battery Modules": 2,
                "Offgrid Maximum Reserve Percent": 96,
                "Offgrid Minimum Reserve Percent": 2,
                "Vdc Nominal": 50,
                "kWh Rating": 13.05
            },
            "PCS": {
                "Comlink Firmware Version": 4.230802,
                "Comlink Serial Number": 0,
                "Inverter/DSP Firmware Version": 6.220607,
                "Inverter/DSP Manufacturer Code": 17748,
                "Inverter/DSP Serial Number": 30
            },
            "health": {
                "Battery": "ok",
                "BatteryInverter": "ok",
                "Grid": "ok"
            },
            "System": {
                "MAC": "00:15:f5:00:00:01",
                "Manufacturer": "Eguana Tech",
                "Model": "Evolve",
                "Options": "Split Phase",
                "Protocol": "Modbus TCP",
                "Serial Number": "ET0000030",
                "Version": "6.220607"
            },
            "Grid Connection": {
                "Grid Validity Timer": 60,
                "Power Factor Default Target": 1,
                "Pre-connect Frequency Maximum": 50.15,
                "Pre-connect Frequency Minimum": 47.6,
                "Pre-connect Vac Maximum": 253,
                "Pre-connect Vac Minimum": 205
            }
        }
    },
    "software_environment": "{\"ansible_version\": \"unknown\", \"os_id\": \"aqua\", \"os_version_id\": \"3.1.11.6\"}",
    "os_version": "#1 SMP Mon Oct 19 11:12:20 UTC 2020",
    "ip_broadcast": "192.168.1.255",
    "os_release": "5.4.72-v7",
    "gateway_address": "192.168.1.1",
    "droplet_public_ip": "159.196.56.211",
    "ip_address": "192.168.1.106",
    "processor": "",
    "alert_def_ts": "1667406420"
}
 */
export type EndpointAttributeDTO = {
  alert_def_ts: string;
  devices: {
    [deviceDevHandler: string]: {
      [attribute: string]: {
        [key: string]: number | string;
      };
    };
  };
  gateway_address: string;
  gateway_interface: string;
  hostname: string;
  ip_address: string;
  ip_broadcast: string;
  ip_netmask: string;
  local_ip_address: string;
  os_release: string;
  os_version: string;
  hw_version?: DropletHardwareVersion;
  processor: string;
  python_implementation: string;
  python_version: string;
  software_environment: string;
  cell_imei?: string;
  cell_iccid?: string;
  cell_imsi?: string;
};

export class EndpointServiceDTO {
  allow_multiple: string;
  device_type_id: string;
  device_type_name: string;
  disable_comms: string;
  disable_control: string;
  duration_unit: string;
  duration_value: number;
  id: string;
  name: string;
  port: number;
  protocol: string;
}

// Endpoint Types
// NOTE: needs to match the to EndpointDTO.type string
export enum DropletType {
  CLOUD_ASSET = 'CloudAsset',
  DROPLET = 'Droplet',
}

export class EndpointDTO {
  controllers: EndpointControllerDTO[];
  devices: EndpointDeviceDTO[];
  endpoint_attributes: EndpointAttributeDTO;
  id: string;
  is_online: boolean;
  last_seen: string;
  last_value_keys: string[];
  pnum: number;
  portfolio_id: string;
  portfolio_name: string;
  services: EndpointServiceDTO[];
  shell_url: string;
  software_version: string;
  target_software_version: string;
  software_version_frontend: string;
  target_software_version_frontend: string;

  //TODO: this is not a part of the payload
  // will remove this when we move the triggerUpdate stuff from the unit service to Endpoint model adapt
  triggerFirmwareUpdate: boolean;

  type: DropletType;
  unit_id: string;
  unit_name: string;
  unit_uuid: string;
  uuid: string;
  long_uuid: string;
  lastSeen: string | null;
  clicked: boolean;

  public static adaptToDropletDisplay(
    endpointDTO: EndpointDTO,
    translationsService: TranslationsService,
  ): DropletDisplay {
    try {
      const result = new DropletDisplay();
      const dropletDevices = EndpointDTO.adaptToDropletDevices(endpointDTO, translationsService);
      result.overview = new DropletOverview();
      result.overview.deviceNumber = dropletDevices.filter((device) => !!device.id).length;
      result.overview.uuid = endpointDTO.long_uuid;
      result.overview.id = endpointDTO.id;
      result.overview.unit_uuid = endpointDTO.unit_uuid;
      //as long as we receive a droplet time stamp (not expired), then we consider a droplet online
      result.overview.statusKeys = ['droplet_timestamp']; // todo - make this a const
      result.overview.lastSeenFromServer = endpointDTO.last_seen;
      result.overview.triggerFirmwareUpdate = endpointDTO.triggerFirmwareUpdate;
      result.overview.type = endpointDTO.type;
      result.attributes = EndpointDTO.adaptToDropletAttribute(endpointDTO, translationsService);
      result.controllers = EndpointControllerDTO.adaptToDropletControllers(
        endpointDTO.controllers,
        translationsService,
      );
      result.devices = DropletDevice.adaptToDeviceDetailsArray(dropletDevices);
      BrowserLogger.log('EndpointDTO.adaptToDropletDisplay', { result, endpointDTO });
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }

  public static adaptToDropletDisplayArray(
    data: { endpoints: EndpointDTO[] },
    translationsService: TranslationsService,
  ): DropletDisplay[] {
    const result = data?.endpoints?.length
      ? data.endpoints.map((endpoint: EndpointDTO) => {
          return EndpointDTO.adaptToDropletDisplay(endpoint, translationsService);
        })
      : [];
    BrowserLogger.log('EndpointDTO.adaptToDropletDisplayArray', { result, data });
    return result;
  }

  public static adaptToDropletDevices(
    endpoint: EndpointDTO,
    translationsService: TranslationsService,
  ): DropletDevice[] {
    const result = endpoint?.devices?.length
      ? endpoint.devices.map((deviceDTO: EndpointDeviceDTO) =>
          EndpointDeviceDTO.adaptToDropletDevice(deviceDTO, endpoint, translationsService),
        )
      : [];
    BrowserLogger.log('EndpointDTO.adaptToDropletDevices', { result, endpoint });
    return result;
  }

  public static adaptToDropletAttribute(
    endpointDTO: EndpointDTO,
    translationsService: TranslationsService,
  ): DropletAttribute {
    const {
      // AlertDefTs,
      GatewayAddress,
      GatewayInterface,
      Hostname,
      IpAddress,
      IpBroadcast,
      IpNetmask,
      LocalIpAddress,
      Software,
      SoftwareVersion,
      CellImei,
      CellImsi,
      CellIccid,
      HardwareVersion,
    } = translationsService.instant('Droplet.Attributes');

    const result = {} as DropletAttribute;

    if (endpointDTO.endpoint_attributes) {
      const {
        gateway_address,
        gateway_interface,
        hostname,
        ip_address,
        ip_broadcast,
        ip_netmask,
        local_ip_address,
        cell_imei,
        cell_imsi,
        cell_iccid,
        hw_version,
      } = endpointDTO.endpoint_attributes;
      const { software_version, software_version_frontend } = endpointDTO;

      // need to do this as if it not present, don't add in the object
      // so we don't have to filter on the html side
      if (gateway_address) {
        result.gatewayAddress = { label: GatewayAddress, value: gateway_address };
      }
      if (gateway_interface) {
        result.gatewayInterface = { label: GatewayInterface, value: gateway_interface };
      }
      if (hostname) {
        result.hostName = { label: Hostname, value: hostname };
      }
      if (ip_address) {
        result.ipAddress = { label: IpAddress, value: ip_address };
      }
      if (ip_broadcast) {
        result.ipBroadcast = { label: IpBroadcast, value: ip_broadcast };
      }
      if (ip_netmask) {
        result.ipNetmask = { label: IpNetmask, value: ip_netmask };
      }
      if (local_ip_address) {
        result.localIpAddress = { label: LocalIpAddress, value: local_ip_address };
      }
      if (software_version) {
        result.software = { label: Software, value: software_version };
      }
      if (software_version_frontend) {
        result.softwareVersion = { label: SoftwareVersion, value: software_version_frontend };
      }
      if (cell_imei) {
        result.cellImei = { label: CellImei, value: cell_imei };
      }
      if (cell_imsi) {
        result.cellImsi = { label: CellImsi, value: cell_imsi };
      }
      if (cell_iccid) {
        result.cellIccid = { label: CellIccid, value: cell_iccid };
      }
      if (hw_version) {
        result.hardwareVersion = { label: HardwareVersion, value: hw_version };
      }
    }
    BrowserLogger.log('EndpointDTO.adaptToDropletAttribute', { result });
    return result;
  }
}

const fixIncomingBooleanParameter = (controllerParameter: DropletControllerParameter): void => {
  // hack - force boolean string to lowercase so future string comparisons work
  //        have put in this function to keep the code completely separate for easy removal
  //        can remove this hack if/when the backend team fix the string being sent over the API
  if (
    controllerParameter.value &&
    DropletDeviceControl.isToggleType(controllerParameter.type) &&
    typeof controllerParameter.value === DataTypeStrings.STRING
  ) {
    const oldValue = controllerParameter.value.toString();
    controllerParameter.value = oldValue.toLowerCase();
    BrowserLogger.log('fixIncomingBooleanParameter', { value: controllerParameter.value, oldValue });
  }
};

export type EndpointControllerResponseDTO = {
  // https://stormcloud-api.test.stormcloud.team/api/v1/site-controllers/controllers/6bbad372/sync/
  data: {
    Status: string;
    Code: number;
    Data: {
      controller_unique_id: string;
      controller_version_number: number;
      controller_type_id: string;
      controllers: EndpointControllerDTO[];
      parameters?: EndpointControllerParameterDTO[];
    };
    Msg: string;
  };
};
