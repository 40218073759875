export enum ListDisplayMode {
  LIST = 1,
  GRID = 2,
}

export enum CrudType {
  CREATE,
  UPDATE,
  DELETE,
}

//  sm	540px	Set grid width to 540px when (min-width: 576px)
//  md	720px	Set grid width to 720px when (min-width: 768px)
//  lg	960px	Set grid width to 960px when (min-width: 992px)
//  xl	1140px	Set grid width to 1140px when (min-width: 1200px)
// column breakpoints
export enum ColBreakpoint {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

export enum HomeCardDataType { // settings the data type, as home card component will be using for portfolio, unit so have to tell the component what type of data is
  UNIT,
  PORTFOLIO,
}

export enum SessionStorage {
  RECENT_PORTFOLIOS = 'recentPortfolios',
  RECENT_UNITS = 'recentUnits',
}

export enum HomepageCards {
  PORTFOLIOS_NUMBER = 6,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  UNITS_NUMBER = 6,
}

// Add_Device connection to droplet via mqtt settings
export enum EndpointConnection {
  TAKING_WHILE_TIMEOUT_MS = 4 * 1000, // 4sec
  TIMEOUT_MS = 10 * 1000, // 10sec
}

export enum DataTypeStrings {
  CHAR = 'char',
  VARCHAR = 'varchar',
  STRING = 'string',
  TEXT = 'text',
  INT = 'int',
  NUMBER = 'number',
  FLOAT = 'float',
  BOOL = 'bool',
  BOOLEAN = 'boolean',
}

export enum BooleanStrings {
  TRUE = 'true',
  FALSE = 'false',
}

export enum PlotlyTraceModes {
  LINES = 'lines',
  MARKERS = 'markers',
}

export enum PlotlyTraceType {
  SCATTER = 'scatter',
}

export enum PlotlyFillMode {
  TO_ZERO_Y = 'tozeroy',
}
// for Portfolio create or edit form
export enum Mode {
  VIEW,
  CREATE,
  EDIT,
  REMOVE,
}

export enum DropletPortTypes {
  USB = 'usb',
  WIFI = 'wifi',
  ETHERNET = 'ethernet',
  BLUETOOTH = 'bluetooth',
  ALL_INTERFACES = 'all-interfaces',
  UNKNOWN = 'unknown',
  RS_485_IO = 'rs-485',
}
export enum DropletHardwareVersion {
  DROPLET_ONE = 'droplet_one 1.0',
  DROPLET_PLUS_ONE = 'droplet_plus 1.0',
  DROPLET_PLUS_TWO = 'droplet_plus 2.0',
  DROPLET_PLUS_TWO_DOT_1 = 'droplet_plus 2.0.1',
}

export enum InstallerButtonActions {
  // 1. Identify the Droplet
  SCAN_QR_CODE = 'scan-qr-code',
  ENTER_CODE_MANUALLY = 'enter-code-manually',
  REGISTER_API_DEVICE = 'register-api-device',
  BACK_TO_WELCOME = 'back-to-welcome',

  // 2. Connect to internet
  CONNECT_TO_WIFI = 'connect-to-wifi',
  CONNECT_TO_ETHERNET = 'connect-to-ethernet',
  DROPLET_FIRMWARE_UPDATE = 'droplet-firmware-update',
  DROPLET_WIFI_SSID = 'droplet-wifi-ssid',
  DROPLET_WIFI_PASSWORD = 'droplet-wifi-password',
  ENABLE_MOBILE_DATA = 'enable-mobile-data',
  CONNECT_MANUALLY_TO_DROPLET = 'connect-manually-to-droplet',

  // 3. Associate it with site
  COLLECTION_SELECTION = 'collection-selection',
  SITE_SELECTION = 'site-selection',
  ADD_DROPLET_TO_SITE = 'add-droplet-to-site',
  MANAGE_DEVICES = 'manage-devices',
  MANAGE_CONTROLLERS = 'manage-controllers',
  INSTALLATION_COMPLETE = 'installation-complete',
}

// backend states are 'Not Configured' | 'Active' | 'Inactive' | 'Deleted' | 'Purged'
// frontend states are 'active' | 'inactive' | 'not configured' | 'deleted' | 'purged'
// setting them to lower case just to avoid any confusion if someone mistakenly changed something in the backend
export enum UnitOperationalStates {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NOT_CONFIGURED = 'not configured',
  DELETED = 'deleted',
  PURGED = 'purged',
}

export enum InstallerContentView {
  // Identify the Droplet Views
  WELCOME_TO_SWITCHDIN = 1,
  SCAN_CODE,
  ENTER_CODE_MANUALLY,

  // Register API Device
  REGISTER_API_DEVICE,

  // Connect to internet
  CONNECT_TO_INTERNET,
  // Connect via wifi
  CONNECT_VIA_WIFI,
  DROPLET_WIFI_SSID,
  DROPLET_WIFI_PASSWORD,
  ENABLE_MOBILE_DATA,
  DROPLET_MANUAL_CONNECT,

  // Droplet firmware update
  DROPLET_FIRMWARE_UPDATE,

  // Droplet Connection screen
  // Connect via ethernet will trigger this as well
  // as there is nothing much to do in ethernet connection
  // just have to check droplet have internet or not
  DROPLET_CONNECTION,

  // Portfolio selection screen
  COLLECTION_SELECTION,
  // site(unit) selection screen
  SITE_SELECTION,
  ADD_DROPLET_TO_SITE,

  // Manage devices screen
  MANAGE_DEVICES,
  MANAGE_CONTROLLERS,

  // Installation done screen
  INSTALLATION_COMPLETE,
}

export enum DropletFirmwareUpdateScreen {
  CHECKING_DROPLET_FIRMWARE = 1,
  UPDATING_DROPLET_FIRMWARE,
  FIRMWARE_UPDATED_SUCCESSFULLY,
  FIRMWARE_ALREADY_UPDATED,
  FIRMWARE_UPDATE_FAILED,
}

export enum FirmwareUpdateMqttStatuses {
  INITIALIZED = 'INITIALIZED',
  DOWNLOADING = 'DOWNLOADING',
  INSTALLING = 'INSTALLING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum FirmwareUpdateMqttVerboseStatuses {
  START = 'START',
  START_SHA_DOWNLOAD = 'START_SHA_DOWNLOAD',
  START_DROPLET_DOWNLOAD = 'START_DROPLET_DOWNLOAD',
  START_VENV_DOWNLOAD = 'START_VENV_DOWNLOAD',
  START_INSTALL = 'START_INSTALL',
  FAIL_UNKNOWN = 'FAIL_UNKNOWN',
  FAIL_ALREADY_UPDATING = 'FAIL_ALREADY_UPDATING',
  FAIL_DROPLET_DOWNLOAD = 'FAIL_DROPLET_DOWNLOAD',
  FAIL_VENV_DOWNLOAD = 'FAIL_VENV_DOWNLOAD',
  SUCCESS_ALREADY_UP_TO_DATE = 'SUCCESS_ALREADY_UP_TO_DATE',
  SUCCESS_DONE = 'SUCCESS_DONE',
}
