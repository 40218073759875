import {
  DropletFirmwareUpdateScreen,
  FirmwareUpdateMqttStatuses,
  FirmwareUpdateMqttVerboseStatuses,
  InstallerContentView,
} from '@class/commons/enums';
import { ICollection, ICollectionList } from '@custom-types/collection/collection.model';
import { ISite } from '@custom-types/site/site.model';
import { createAction, props } from '@ngrx/store';

// expect droplet uuid in full format
// e.g. 00000000-0000-0000-0000-b827eb46dea1
export const checkDropletIsClaimed = createAction(
  '[Installer] Check Droplet Is Claimed',
  props<{ dropletUuid: string }>(),
);

export const setDropletIsClaimed = createAction(
  '[Installer] Set Droplet Is Claimed',
  props<{
    isDropletClaimed: boolean | null;
    isUserHasPermission: boolean | null;
  }>(),
);

export const setDropletUuidAndSSID = createAction(
  '[Installer] Set Droplet UUID and SSID',
  props<{ dropletUuid: string; dropletSSID: string }>(),
);

export const setSelectedSSID = createAction('[Installer] Set Selected SSID', props<{ selectedSSID: string }>());

export const setIdentifyTheDropletViewLoadingState = createAction(
  '[Installer] Set Identify The Droplet View Loading State',
  props<{ isLoading: boolean; isError: boolean; error: Error | null }>(),
);

export const setInstallerContentView = createAction(
  '[Installer] Set Installer Content View',
  props<{ contentView: InstallerContentView }>(),
);

export const setDropletDoesNotExist = createAction(
  '[Installer] Set Droplet Does Not Exist',
  props<{ dropletDoesNotExist: boolean }>(),
);

export const setIsDropletConnectedToInternet = createAction(
  '[Installer] Set Droplet Connected To Internet',
  props<{ isDropletConnectedToInternet: boolean }>(),
);

export const establishConnectionWithDropletOverMqtt = createAction(
  '[Installer] Establish Connection With Droplet Over MQTT',
  props<{ dropletUuid: string }>(),
);

export const getDropletData = createAction('[Installer] Get Droplet Data', props<{ dropletUuid: string }>());
export const setDropletDataLoadingState = createAction(
  '[Installer] Set Droplet Data Loading State',
  props<{ isLoading: boolean; isError: boolean; error: Error | null }>(),
);
export const setDropletFirmwareScreenContent = createAction(
  '[Installer] Set Droplet Firmware Screen Content',
  props<{ screenContent: DropletFirmwareUpdateScreen }>(),
);
export const connectToDropletFirmwareUpdateMqtt = createAction(
  '[Installer] Connect To Droplet Firmware Update MQTT',
  props<{ dropletUuid: string }>(),
);
export const triggerDropletFirmwareUpdate = createAction(
  '[Installer] Trigger Droplet Firmware Update',
  props<{ dropletUuid: string }>(),
);
export const setDropletFirmwareUpdateContent = createAction(
  '[Installer] Set Droplet Firmware Update Content',
  props<{
    progress: number;
    status: FirmwareUpdateMqttStatuses;
    verboseStatus: FirmwareUpdateMqttVerboseStatuses;
    extraInfo?: { [index: string]: number | string | boolean };
  }>(),
);

export const getCollectionsList = createAction('[Installer] Get Collections List');
export const setCollectionsList = createAction(
  '[Installer] Set Collections List',
  props<{ collectionsList: ICollectionList[] }>(),
);

export const setCollectionListLoadingState = createAction(
  '[Installer] Set Collection ListLoading State',
  props<{ isLoading: boolean; isError: boolean; error: Error | null }>(),
);
export const setSelectedCollectionByUuid = createAction(
  '[Installer] Set Selected Collection By Id',
  props<{ collectionUuid: string }>(),
);

export const getSelectedCollectionDetail = createAction(
  '[Installer] Get Selected Collection Detail',
  props<{ collectionUuid: string }>(),
);
export const setSelectedCollectionDetail = createAction(
  '[Installer] Set Selected Collection Detail',
  props<{ collectionDetail: ICollection }>(),
);
export const setCollectionDetailLoadingState = createAction(
  '[Installer] Set Collection Detail Loading State',
  props<{ isLoading: boolean; isError: boolean; error: Error | null }>(),
);
export const setSelectedSiteByUuid = createAction(
  '[Installer] Set Selected Site By Uuid',
  props<{ siteUuid: string }>(),
);
export const getSelectedSiteDetailByUuid = createAction(
  '[Installer] Get Selected Site Detail By Uuid',
  props<{ siteUuid: string }>(),
);
export const setSelectedSite = createAction('[Installer] Set Selected Site', props<{ siteDetail: ISite }>());

export const getSiteDetailByDropletUuid = createAction(
  '[Installer] Get Site Detail By Droplet Uuid',
  props<{ dropletUuid: string }>(),
);

export const resetInstallerState = createAction('[Installer] Reset Installer State');
