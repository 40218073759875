import { createReducer, on } from '@ngrx/store';
import {
  resetInstallerState,
  setDropletDoesNotExist,
  setDropletFirmwareScreenContent,
  setDropletFirmwareUpdateContent,
  setDropletIsClaimed,
  setDropletUuidAndSSID,
  setIdentifyTheDropletViewLoadingState,
  setInstallerContentView,
  setIsDropletConnectedToInternet,
  setCollectionListLoadingState,
  setCollectionsList,
  setSelectedSSID,
  setSelectedCollectionByUuid,
  setSelectedCollectionDetail,
  setSelectedSiteByUuid,
  setCollectionDetailLoadingState,
  setSelectedSite,
} from './installer.action';
import {
  DropletFirmwareUpdateScreen,
  FirmwareUpdateMqttStatuses,
  FirmwareUpdateMqttVerboseStatuses,
  InstallerContentView,
} from '@class/commons/enums';
import { ICollection, ICollectionList } from '@custom-types/collection/collection.model';
import { ICollectionUnitSet, ISite } from '@custom-types/site/site.model';

export interface InstallerState {
  isDropletClaimed: boolean;
  isUserHasPermission: boolean;
  dropletUuid: string; // This is the UUID of the droplet i.e., 00000000-0000-0000-0000-b827ebef0e08
  dropletSSID: string; // This is the SSID of the droplet to connect to it i.e., Dropletb827ebef0e08
  selectedSSID: string; // This is the SSID of the selected network to connect to the droplet i.e., MyNetwork
  contentView: InstallerContentView;

  // This is for edge cases
  // if the droplet does not exist in our system, can be a glitch
  // but if it's a real case, we need to handle it
  // and the droplet will get registered in our system once it gets connected to the internet
  dropletDoesNotExist: boolean;

  isDropletConnectedToInternet: boolean;

  identifyTheDropletViewLoadingState: {
    isLoading?: boolean;
    isError?: boolean;
    error?: string | null;
  };

  dropletFirmwareUpdateContent: {
    status: FirmwareUpdateMqttStatuses | 'DropletFirmware.InitiatingUpdate';
    verboseStatus: FirmwareUpdateMqttVerboseStatuses;
    progress: number;
    extraInfo?: { [index: string]: number | string | boolean };
    screenView: DropletFirmwareUpdateScreen;
  };

  collectionsList: ICollectionList[];
  collectionListLoadingState: {
    isLoading: boolean;
    isError: boolean;
    error: string | null;
  };
  selectedCollection: ICollectionList;

  // selected collection site list
  selectedCollectionDetail: ICollection;
  selectedCollectionDetailLoadingState: {
    isLoading: boolean;
    isError: boolean;
    error: string | null;
  };
  selectedSite: ICollectionUnitSet | ISite;
}

export const initialState: InstallerState = {
  isDropletClaimed: false,
  isUserHasPermission: false,
  dropletUuid: null,
  dropletSSID: null,
  selectedSSID: null,
  contentView: InstallerContentView.WELCOME_TO_SWITCHDIN,
  dropletDoesNotExist: false,
  isDropletConnectedToInternet: false,
  identifyTheDropletViewLoadingState: null,
  dropletFirmwareUpdateContent: {
    status: 'DropletFirmware.InitiatingUpdate',
    verboseStatus: null,
    progress: 0,
    extraInfo: null,
    screenView: DropletFirmwareUpdateScreen.CHECKING_DROPLET_FIRMWARE,
  },
  collectionsList: [],
  collectionListLoadingState: {
    isLoading: false,
    isError: false,
    error: null,
  },
  selectedCollection: null,
  selectedCollectionDetail: null,
  selectedCollectionDetailLoadingState: {
    isLoading: false,
    isError: false,
    error: null,
  },
  selectedSite: null,
} as InstallerState;

export const appInstallerReducer = createReducer(
  initialState,
  on(setDropletUuidAndSSID, (state, { dropletUuid, dropletSSID }) => {
    return {
      ...state,
      dropletUuid,
      dropletSSID,
    };
  }),
  on(setSelectedSSID, (state, { selectedSSID }) => {
    return {
      ...state,
      selectedSSID,
    };
  }),
  on(setDropletIsClaimed, (state, { isDropletClaimed, isUserHasPermission }) => {
    return {
      ...state,
      isDropletClaimed,
      isUserHasPermission,
    };
  }),
  on(setIdentifyTheDropletViewLoadingState, (state, { isLoading, isError, error }) => {
    return {
      ...state,
      identifyTheDropletViewLoadingState: {
        isLoading,
        isError,
        error: error?.message ?? null,
      },
    };
  }),
  on(setInstallerContentView, (state, { contentView }) => {
    return {
      ...state,
      contentView,
    };
  }),
  on(setDropletDoesNotExist, (state, { dropletDoesNotExist }) => {
    return {
      ...state,
      dropletDoesNotExist,
    };
  }),
  on(setIsDropletConnectedToInternet, (state, { isDropletConnectedToInternet }) => {
    return {
      ...state,
      isDropletConnectedToInternet,
    };
  }),
  on(setDropletFirmwareScreenContent, (state, { screenContent }) => {
    return {
      ...state,
      dropletFirmwareUpdateContent: {
        ...state.dropletFirmwareUpdateContent,
        screenView: screenContent,
      },
    };
  }),
  on(setDropletFirmwareUpdateContent, (state, { status, verboseStatus, progress, extraInfo }) => {
    return {
      ...state,
      dropletFirmwareUpdateContent: {
        ...state.dropletFirmwareUpdateContent,
        status,
        verboseStatus,
        progress,
        extraInfo,
      },
    };
  }),
  on(setCollectionsList, (state, { collectionsList }) => {
    return {
      ...state,
      collectionsList,
    };
  }),
  on(setCollectionListLoadingState, (state, { isLoading, isError, error }) => {
    return {
      ...state,
      collectionListLoadingState: {
        isLoading,
        isError,
        error: error?.message ?? null,
      },
    };
  }),
  on(setSelectedCollectionByUuid, (state, { collectionUuid }) => {
    return {
      ...state,
      selectedCollection: collectionUuid
        ? state.collectionsList.find((collection) => collection.uuid === collectionUuid)
        : null,
    };
  }),
  on(setSelectedCollectionDetail, (state, { collectionDetail }) => {
    return {
      ...state,
      selectedCollectionDetail: collectionDetail,
    };
  }),
  on(setCollectionDetailLoadingState, (state, { isLoading, isError, error }) => {
    return {
      ...state,
      selectedCollectionDetailLoadingState: {
        isLoading,
        isError,
        error: error?.message ?? null,
      },
    };
  }),
  on(setSelectedSiteByUuid, (state, { siteUuid }) => {
    return {
      ...state,
      selectedSite: state.selectedCollectionDetail.unit_set.find((site) => site.uuid === siteUuid),
    };
  }),
  on(setSelectedSite, (state, { siteDetail }) => {
    return {
      ...state,
      selectedSite: siteDetail,
    };
  }),
  on(resetInstallerState, () => {
    return initialState;
  }),
);
