import { ThemeInterface } from './theme-interface';
import { defaultTheme } from './default-theme';
import { getThemeIconsLight, ThemeMode, ThemeMap } from './theme-map';

const ausgridTheme: ThemeInterface = {
  // TODO
  // Using the evo-power for ausgrid
  // if you want to test, change your theme to evo power
  name: ThemeMap.AUSGRID,
  type: ThemeMode.DARK,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#062751' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '6, 39, 81' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#052247' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#1f3d62' },
    ],
    textPrimary: defaultTheme.styles.textPrimary,
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: defaultTheme.styles.textSecondary,
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#449DD6' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '68, 157, 214' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#3c8abc' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#57a7da' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#1D6BAB' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '29,107,171' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255,255,255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#1a5e96' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#347ab3' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#062751' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '6,39,81' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#052247' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#1f3d62' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#062751' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '6, 39, 81' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#052247' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#1f3d62' },
    ],
    solar: defaultTheme.styles.solar,
    consumption: defaultTheme.styles.consumption,
    battery: defaultTheme.styles.battery,
    consumed: defaultTheme.styles.consumed,
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.AUSGRID],
    style: {
      height: '33px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    primary: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' },
    light: { value: '#2E4C68', opacValue: 'rgba(46, 76, 104, 0.4)' },
    secondary: { value: '#31CEFF', opacValue: 'rgba(49, 206, 255, 0.4)' },
    tertiary: { value: '#008A32', opacValue: 'rgba(0, 178, 153, 0.4)' },

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#0064A7',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#062751' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '6, 39, 81' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#052247' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#1f3d62' },
    ],
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#1D6BA8' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '29,107,168' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#1a5e94' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#347ab1' },
    ],
    success: [
      { themeVariable: '--ion-color-success', value: '#74AF27' },
      { themeVariable: '--ion-color-success-rgb', value: '116,175,39' },
      { themeVariable: '--ion-color-success-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-shade', value: '#669a22' },
      { themeVariable: '--ion-color-success-tint', value: '#82b73d' },
    ],
    warning: [
      { themeVariable: '--ion-color-warning', value: '#F29E39' },
      { themeVariable: '--ion-color-warning-rgb', value: '242,158,57' },
      { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-shade', value: '#d58b32' },
      { themeVariable: '--ion-color-warning-tint', value: '#f3a84d' },
    ],
    danger: [
      { themeVariable: '--ion-color-danger', value: '#DB3535' },
      { themeVariable: '--ion-color-danger-rgb', value: '219,53,53' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#c12f2f' },
      { themeVariable: '--ion-color-danger-tint', value: '#df4949' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#E6FACD' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '230, 250, 205' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#cadcb4' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#e9fbd2' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FDECD2' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '253, 236, 210' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#dfd0b9' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#fdeed7' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FFDFDF' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '255, 223, 223' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#e0c4c4' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#ffe2e2' },
    ],
  },
  svgIcons: {
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#4696EC' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '70,150,236' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#000000' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#3e84d0' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#59a1ee' },
    ],
  },
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#F4FBFE' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '244, 251, 254' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#d7dde0' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#f5fbfe' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#ECF4F8' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '236, 244, 248' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d0d7da' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#eef5f9' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FAFAFB' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '250, 250, 251' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dcdcdd' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fbfbfb' },
    ],
  },
};

export { ausgridTheme };
