import { Component, Input } from '@angular/core';
import { CustomButtons } from '../../../classes/commons/custom-buttons';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LOADING_SPINNER_DURATION_MS } from '@class/commons/constants';

export enum ButtonType {
  DEFAULT = 'default',
  PRIMARY_BLOCK_OUTLINE = 'primary_block_outline',
  PRIMARY_SOLID_BLOCK = 'primary_solid_block',
  PRIMARY_ROUND_OUTLINE_DEFAULT = 'primary_round_outline_default',
  PRIMARY_ROUND_SOLID_DEFAULT = 'primary_round_solid_default',
  PRIMARY_SOLID_DEFAULT = 'primary_solid_default',
  PRIMARY_OUTLINE_DEFAULT = 'primary_outline_default',
  DANGER_LITE_SOLID_BLOCK = 'danger_lite_solid_block',
  DANGER_LITE_SOLID_DEFAULT = 'danger_lite_solid_default',
}

type ButtonFill = 'clear' | 'outline' | 'solid';
type ButtonExpand = 'block' | 'full' | undefined;
type ButtonSize = 'small' | 'default' | 'large';
type ButtonColor = 'button-primary' | 'danger-lite';

type ButtonSettings = {
  class: string;
  fill: ButtonFill;
  color: ButtonColor;
  expand: ButtonExpand;
  textStyle: string[];
  size?: ButtonSize;
};

const getButtonSettings: Record<ButtonType, ButtonSettings> = {
  [ButtonType.DEFAULT]: {
    class: 'default',
    fill: 'solid',
    color: 'button-primary',
    expand: 'block',
    textStyle: ['text-lg', 'ion-text-capitalize'],
  },
  [ButtonType.PRIMARY_BLOCK_OUTLINE]: {
    class: 'block-outline',
    fill: 'outline',
    color: 'button-primary',
    expand: 'block',
    textStyle: ['text-lg', 'ion-text-capitalize'],
  },
  [ButtonType.PRIMARY_SOLID_BLOCK]: {
    class: 'primary-solid-block',
    fill: 'solid',
    color: 'button-primary',
    expand: 'block',
    textStyle: ['ion-text-uppercase', 'text-lg'],
  },
  [ButtonType.PRIMARY_ROUND_OUTLINE_DEFAULT]: {
    class: 'round-outline-default',
    fill: 'outline',
    color: 'button-primary',
    expand: undefined,
    textStyle: ['text-md'],
  },
  [ButtonType.PRIMARY_ROUND_SOLID_DEFAULT]: {
    class: 'round-solid-default',
    fill: 'solid',
    color: 'button-primary',
    expand: undefined,
    textStyle: ['text-md'],
  },
  [ButtonType.PRIMARY_SOLID_DEFAULT]: {
    class: 'solid-default',
    fill: 'solid',
    color: 'button-primary',
    expand: undefined,
    textStyle: ['text-lg'],
  },
  [ButtonType.PRIMARY_OUTLINE_DEFAULT]: {
    class: 'outline-default',
    fill: 'outline',
    color: 'button-primary',
    expand: undefined,
    textStyle: ['text-lg'],
  },
  [ButtonType.DANGER_LITE_SOLID_BLOCK]: {
    class: 'danger-lite-solid-block',
    fill: 'solid',
    color: 'danger-lite',
    expand: 'block',
    textStyle: ['ion-text-uppercase', 'text-lg'],
  },
  [ButtonType.DANGER_LITE_SOLID_DEFAULT]: {
    class: 'danger-lite-solid-default',
    fill: 'solid',
    color: 'danger-lite',
    expand: undefined,
    textStyle: ['text-lg'],
  },
};

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class ButtonComponent extends CustomButtons {
  @Input({
    transform: (value: string | boolean) => {
      if (typeof value === 'string') {
        return value !== 'false';
      }

      return value;
    },
  })
  loading: boolean = false;
  @Input() spinner?: string;

  buttonSettings: ButtonSettings;

  constructor() {
    super();

    this.buttonSettings = getButtonSettings[ButtonType.DEFAULT];
  }

  @Input()
  set buttonType(type: ButtonType) {
    this.buttonSettings = getButtonSettings[type];
  }

  @Input() formSubmit: boolean = false;
  @Input() iconSrc: string | null = null;

  readonly LOADING_SPINNER_DURATION_MS = LOADING_SPINNER_DURATION_MS;
}
