import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.api-service';
import { DeviceConfigurationPostApi } from 'app/pages/modals/add-device-configuration/utils/add-device-types';
import { RequestMethod } from '@service/common/api-wrapper.service';
import { ApiResponse } from 'app/shared/api-utils';
import { EndpointDeviceDTO } from '@class/units/endpoint/endpoint-payload.model';

export interface GetDeviceDetailParams {
  deviceId?: string;
}

export interface DeconfigureDeviceParams {
  deviceId: string;
}

export const queryKeys = {
  all: ['devices'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: (params: GetDeviceDetailParams) => [...queryKeys.details(), params.deviceId] as const,
};

@Injectable({
  providedIn: 'root',
})
export class DevicesApiService extends BaseApiService {
  constructor() {
    super('/api/v2/devices');
  }

  getDevice(params: GetDeviceDetailParams) {
    return this._query<EndpointDeviceDTO>({
      queryKey: queryKeys.detail(params),
      queryFn: () =>
        this.request({
          path: params.deviceId,
        }),
      enabled: !!params.deviceId,
    });
  }

  configureDevice() {
    return this._mutation({
      mutationFn: (device: DeviceConfigurationPostApi) =>
        this.request<ApiResponse<EndpointDeviceDTO>>({
          baseUrl: '/api/v1/iec61850',
          path: 'configure',
          data: device,
          method: RequestMethod.POST,
        }),
      onSuccess: (data) => {
        this._queryClient.setQueryData(queryKeys.detail({ deviceId: data.Data.id }), data.Data);
      },
    });
  }

  deconfigureDevice() {
    return this._mutation({
      mutationFn: (params: DeconfigureDeviceParams) =>
        this.request<void>({
          path: params.deviceId,
          method: RequestMethod.DELETE,
        }),
    });
  }
}
