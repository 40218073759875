import { BrowserLogger } from '@class/core/browser-logger';
import { VppUnitDeviceTypes } from '../vpp/vpp-unit.model';
import { HomeCardDataType, UnitOperationalStates } from './enums';
import { PermissionDTO } from './permissions/permission-constants';
import { DateTime } from 'luxon';
import { UnitOperationalStateApi } from '@custom-types/unit/unit-operational-state-api.model';

// TODO: get rid of snake_case and implement camelCase
export type UnitDeviceDTO = {
  category: VppUnitDeviceTypes;
  endpoint_uuid: string;
  full_name: string;
  id: string;
  uuid: string;
};

export type ProgramAgent = {
  program: {
    uuid: string;
    name: string;
  };
  agent: {
    uuid: string;
    code: string;
    name: string;
  };
};

export type TariffDTO = [string, string];

export interface UnitDTO {
  address: string;
  city: string;
  contact_phone: string;
  country: string;
  current_power: number;
  disable_alarms: boolean | null;
  external_identifier: string | null;
  graphic: string;
  image?: string;
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  nmi: string;
  online_status: number;
  peak_capacity: number;
  peak_power: number;
  peak_power_yesterday: number;
  permissions: PermissionDTO[];
  portfolio: {
    id: string;
    name: string;
    uuid: string;
  };
  postcode: string;
  program_agent: ProgramAgent;
  state: string;
  taken_offline: boolean;
  tariff_endpoint: string | null;
  tariff_options: TariffDTO[];
  tariffs: string[];
  timezone: string;
  total_energy_today: number;
  total_lifetime_energy: number;
  tzoffset: number;
  uuid: string;
  devices?: UnitDeviceDTO[];
  role_code?: string;
  user_control?: boolean;
  production?: number;
  production_prev?: number;
  consumption?: number;
  battery_charged?: number;
  operational_state: UnitOperationalStateApi;
  pending_action: boolean;
}

const OFF_GRID_SVG = 'offgrid.svg';
// this needed a lot of cleanup
export class Unit {
  id: string;
  name: string;
  address: string;
  image: string;
  latitude: number;
  longitude: number;
  onlineStatus: number;
  city: string;
  contact_phone: string;
  country: string;
  disable_alarms: boolean | null;
  graphic: string;
  portfolio: {
    id: string;
    name: string;
    uuid: string;
  };
  postcode: string;
  role_code: string;
  state: string;
  tariff_endpoint: string;
  tariff_options: Array<TariffDTO>;
  tariffs: string[];
  timezone: string;
  tzoffset: number;
  user_control: boolean;
  uuid: string;
  dataType: HomeCardDataType;
  pvExist: boolean;
  bessExist: boolean;
  acExist: boolean;
  hwExist: boolean;
  hasNoDevices: boolean;
  isScadaUnit: boolean;
  devices?: UnitDeviceDTO[];
  nmi: string;
  external_identifier: string;
  permissions: Array<PermissionDTO>;
  production: number;
  consumption: number;
  battery_charged: number;
  production_prev: number;
  isOffGrid: boolean;
  program_agent: ProgramAgent;
  operationalState: UnitOperationalStates;
  actionPending: boolean;

  constructor(itemPayload?: UnitDTO) {
    BrowserLogger.log('Unit.constructor');
    if (itemPayload) {
      this.adapt(itemPayload);
    }
  }

  private adapt(itemPayload: UnitDTO): void {
    let ac = false,
      pv = false,
      bess = false,
      hw = false,
      hasNoDevices = true;
    let isScadaUnit = false;
    itemPayload.devices?.forEach((device) => {
      switch (device.category as string) {
        case 'A/C':
          ac = true;
          hasNoDevices = false;
          break;
        case 'BESS':
          bess = true;
          hasNoDevices = false;
          break;
        case 'PV-INV':
          pv = true;
          hasNoDevices = false;
          break;
        case 'HW':
          hw = true;
          hasNoDevices = false;
          break;
        case 'Modbus':
          isScadaUnit = true;
          break;
        default:
          break;
      }
    });

    this.id = itemPayload.id;
    this.name = itemPayload.name;
    this.address = itemPayload.address;
    this.image = itemPayload.image;
    this.latitude = itemPayload.latitude;
    this.longitude = itemPayload.longitude;
    this.onlineStatus = itemPayload.online_status;
    this.city = itemPayload.city ? itemPayload.city : '';
    this.contact_phone = itemPayload.contact_phone;
    this.country = itemPayload.country ? itemPayload.country : '';
    this.disable_alarms = itemPayload.disable_alarms;
    this.graphic = itemPayload.graphic ? itemPayload.graphic : '';
    this.portfolio = itemPayload.portfolio ? itemPayload.portfolio : { id: '', name: '', uuid: '' };
    this.postcode = itemPayload.postcode ? itemPayload.postcode : '';
    this.role_code = itemPayload.role_code ? itemPayload.role_code : '';
    this.state = itemPayload.state ? itemPayload.state : '';
    this.tariff_endpoint = itemPayload.tariff_endpoint ? itemPayload.tariff_endpoint : '';
    this.tariff_options = itemPayload.tariff_options ? itemPayload.tariff_options : [];
    this.tariffs = itemPayload.tariffs;
    this.timezone =
      itemPayload.timezone && itemPayload.timezone !== 'etc/UTC' ? itemPayload.timezone : DateTime.local().zoneName;
    this.tzoffset = itemPayload.tzoffset ? itemPayload.tzoffset : new Date().getTimezoneOffset();
    this.user_control = itemPayload.user_control ?? false;
    this.uuid = itemPayload.uuid ? itemPayload.uuid : '';
    this.dataType = HomeCardDataType.UNIT;
    this.pvExist = pv;
    this.bessExist = bess;
    this.acExist = ac;
    this.hwExist = hw;
    this.hasNoDevices = hasNoDevices;
    this.isScadaUnit = isScadaUnit;
    this.devices = itemPayload.devices ? itemPayload.devices : [];
    this.nmi = itemPayload.nmi;
    this.external_identifier = itemPayload.external_identifier;
    this.permissions = itemPayload.permissions;
    this.production = itemPayload.production;
    this.consumption = itemPayload.consumption;
    this.battery_charged = itemPayload.battery_charged;
    this.production_prev = itemPayload.production_prev;
    this.isOffGrid = itemPayload.graphic ? itemPayload.graphic.toLowerCase() === OFF_GRID_SVG : false;
    this.program_agent = itemPayload.program_agent;
    this.operationalState = itemPayload.operational_state?.toLowerCase() as UnitOperationalStates;
    this.actionPending = itemPayload.pending_action;

    BrowserLogger.log('Unit.adapt', { self: this, itemPayload });
  }
}
