import { ThemeInterface } from './theme-interface';
import { AppAssets, getThemeIconsLight, ThemeFooterIcons, ThemeMode, ThemeMap } from './theme-map';

const defaultTheme: ThemeInterface = {
  name: ThemeMap.SWITCHDIN,
  type: ThemeMode.LIGHT,
  styles: {
    global: [],

    // Button Normal
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#008A32' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#00792C' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#1A9647' },
    ],
    // Text Main
    textPrimary: [
      { themeVariable: '--ion-color-text-primary', value: '#222421' },
      { themeVariable: '--ion-color-text-primary-rgb', value: '34, 36, 33' },
      { themeVariable: '--ion-color-text-primary-contrast', value: '#FFFFFF' },
      { themeVariable: '--ion-color-text-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-primary-shade', value: '#1E201D' },
      { themeVariable: '--ion-color-text-primary-tint', value: '#383A37' },
    ],
    // light
    textTertiary: [
      { themeVariable: '--ion-color-text-tertiary', value: '#424940' },
      { themeVariable: '--ion-color-text-tertiary-rgb', value: '66, 73, 64' },
      { themeVariable: '--ion-color-text-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-tertiary-shade', value: '#3A4038' },
      { themeVariable: '--ion-color-text-tertiary-tint', value: '#555B53' },
    ],
    // Text Additional, medium
    textSecondary: [
      { themeVariable: '--ion-color-text-secondary', value: '#767873' },
      { themeVariable: '--ion-color-text-secondary-rgb', value: '118, 120, 115' },
      { themeVariable: '--ion-color-text-secondary-contrast', value: '#171D1B' },
      { themeVariable: '--ion-color-text-secondary-contrast-rgb', value: '23, 29, 27' },
      { themeVariable: '--ion-color-text-secondary-shade', value: '#686A65' },
      { themeVariable: '--ion-color-text-secondary-tint', value: '#848681' },
    ],

    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#F5FBF7' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '245, 251, 247' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#222421' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '34, 36, 33' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#DBDBD9' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#FAFAF8' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#008A32' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#00792C' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#1A9647' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#3D6562' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '61, 101, 98' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#365956' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#507472' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#2C322A' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '44, 50, 42' },
      // the contrast supposed to be white, but changed it
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#424940' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '66, 73, 64' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#272C25' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#41473F' },
    ],

    solar: [
      { themeVariable: '--ion-color-solar', value: '#FFBA36' },
      { themeVariable: '--ion-color-solar-rgb', value: '255, 186, 54' },
      { themeVariable: '--ion-color-solar-contrast', value: '#281900' },
      { themeVariable: '--ion-color-solar-contrast-rgb', value: '40, 25, 0' },
      { themeVariable: '--ion-color-solar-shade', value: '#E0A430' },
      { themeVariable: '--ion-color-solar-tint', value: '#FFC14A' },
    ],
    battery: [
      { themeVariable: '--ion-color-battery', value: '#008A32' },
      { themeVariable: '--ion-color-battery-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-battery-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-battery-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-battery-shade', value: '#00792C' },
      { themeVariable: '--ion-color-battery-tint', value: '#1A9647' },
    ],
    consumption: [
      { themeVariable: '--ion-color-consumption', value: '#3D6562' },
      { themeVariable: '--ion-color-consumption-rgb', value: '61, 101, 98' },
      { themeVariable: '--ion-color-consumption-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumption-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumption-shade', value: '#365956' },
      { themeVariable: '--ion-color-consumption-tint', value: '#507472' },
    ],
    consumed: [
      { themeVariable: '--ion-color-consumed', value: '#4696ec' },
      { themeVariable: '--ion-color-consumed-rgb', value: '70, 150, 236' },
      { themeVariable: '--ion-color-consumed-contrast', value: '#222421' },
      { themeVariable: '--ion-color-consumed-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-consumed-shade', value: '#3e84d0' },
      { themeVariable: '--ion-color-consumed-tint', value: '#59a1ee' },
    ],
  },

  headerLogo: {
    path: getThemeIconsLight[ThemeMap.SWITCHDIN],
    style: {
      height: '30px',
    },
  },

  footerLogo: {
    path: `${AppAssets.FOOTER_LIGHT}${ThemeFooterIcons.DEFAULT}`,
    style: {
      height: '30px',
      width: '12em',
    },
  },
  footerColoredLogo: `${AppAssets.FOOTER_LIGHT}${ThemeFooterIcons.DEFAULT}`,
  iconsSvgPath: AppAssets.ICONS_SVG_LIGHT,
  chartColors: {
    primary: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' }, // solar
    light: { value: '#2E4C68', opacValue: 'rgba(46, 76, 104, 0.4)' }, // consumption
    secondary: { value: '#4696EC', opacValue: 'rgba(70, 150, 236, 0.4)' }, // consumed
    tertiary: { value: '#008A32', opacValue: 'rgba(0, 138, 50, 0.4)' }, // battery

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: {
    avail: '#008A32',
    dispatch: '#626363',
    unscheduled_dispatch: '#B03A2E',
    manual_dispatch: '#B7950B',
    unassociated_dispatch: '#9c0897',
    constraint: '#befc03',
    auto_dispatch: '#7b97b8',
    selected_avail: '#00BFFF',
    selected_enabled: '#3333BE',
    selected_dispatch: '#00008B',
    selected_constraint: '#07b8f2',
  },
  streamChartColor: '#008A32',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#008A32' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#00792C' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#1A9647' },
    ],
    // UI Elements
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#008A32' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#00792C' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#1A9647' },
    ],

    // Action Green
    success: [
      { themeVariable: '--ion-color-success', value: '#008A32' },
      { themeVariable: '--ion-color-success-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-success-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-success-shade', value: '#00792C' },
      { themeVariable: '--ion-color-success-tint', value: '#1A9647' },
    ],
    // Action Yellow
    warning: [
      { themeVariable: '--ion-color-warning', value: '#F56226' },
      { themeVariable: '--ion-color-warning-rgb', value: '245, 98, 38' },
      { themeVariable: '--ion-color-warning-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-warning-shade', value: '#D85621' },
      { themeVariable: '--ion-color-warning-tint', value: '#F6723C' },
    ],
    // Action Red
    danger: [
      { themeVariable: '--ion-color-danger', value: '#BE0C1C' },
      { themeVariable: '--ion-color-danger-rgb', value: '190, 12, 28' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#A70B19' },
      { themeVariable: '--ion-color-danger-tint', value: '#C52433' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#B3F2B2' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '179, 242, 178' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#002106' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 33, 6' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#9ED59D' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#BBF3BA' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FFDEAC' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '255, 222, 172' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#281900' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '40, 25, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#E0C397' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#FFE1B4' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FFDAD6' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '255, 218, 214' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#410003' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '65, 0, 3' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#E0C0BC' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#FFDEDA' },
    ],
  },
  svgIcons: {
    // Icons / Home
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#008A32' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '0, 138, 50' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#00792C' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#1A9647' },
    ],
  },
  // Tables
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#F9F9F7' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '249, 249, 247' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#222421' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '34, 36, 33' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#DBDBD9' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#FAFAF8' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#F9F9F7' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '249, 249, 247' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#222421' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '34, 36, 33' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#DBDBD9' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#FAFAF8' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#F9F9F7' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '249, 249, 247' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#222421' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '34, 36, 33' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#DBDBD9' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#FAFAF8' },
    ],
  },
};
export { defaultTheme };
